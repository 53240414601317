import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CueCuestionarioRespuestasService } from "src/app/services/cuestionarios/cueCuestionarioRespuestas.service";
import { RespuestaInterface } from "src/app/models/respuesta";
import { FormsService } from "src/app/services/forms/forms.service";
import { LayoutService } from "src/app/services/layouts/layouts.service";
import { CueCuestionarioRespuestaInterface } from "src/app/models/cueCuestionarioRespuesta";

@Component({
  selector: 'app-cueTemplate',
  templateUrl: './cueTemplate.component.html',
  styleUrls: ['./cueTemplate.component.css']
})

export class CueTemplateComponent implements OnInit {
  form: FormGroup;
  IdForm: any;
  tabs:Array<any>;
  sections: Array<any>;
  items: Array<any>;

  constructor(
    public layoutService: LayoutService,
    private formService: FormsService,
    private cueCuestionarioRespuestaService:CueCuestionarioRespuestasService,
    private route: ActivatedRoute
  ) {
    layoutService.title = "Test Cuestionario";
  }

  ngOnInit() {
    this.IdForm = this.route.snapshot.paramMap.get('id');
    this.retrieveForm();
  }

  retrieveForm() {
    let formGroup: any = {};

    this.formService.get(this.IdForm)
      .subscribe(
        data => {
          this.tabs=data.tabs;
          this.tabs.forEach(tab=>{
            tab.secctions.forEach(sec => {
              sec.items.forEach(item => {
                formGroup[item.name] = new FormControl('');
              });  
            });
          });
          this.form = new FormGroup(formGroup);
        },
        error => {
          console.log(error);
        });
  }

  openCity2(evt,tab) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "gradient-button-3");
    }

    document.getElementById(tab.id).style.display = "block";
    evt.currentTarget.className += "active";
  }

  openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  OnSave() {
    let listRespuesta:RespuestaInterface[]=[];
    let model:CueCuestionarioRespuestaInterface={
      idForm:Number(this.IdForm),
      idCuestionario:Number(2),
      idTab:Number("0"),
      status:'I',
      respuestas:listRespuesta
    };

    this.tabs.forEach(tab=>{
      tab.secctions.forEach(sec => {
        sec.items.forEach(item => {
          let respuestaModel:RespuestaInterface={
            columnName:item.name,
            respuesta:this.form.controls[item.name].value
          };
          
          model.respuestas.push(respuestaModel);
        });  
      });
    });

    this.cueCuestionarioRespuestaService.post(model)
    .subscribe(
      data=>{
        console.log(data);
      },
      error=>{
        console.log(error);
      }
    );
  }
}