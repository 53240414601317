import { Component } from "@angular/core";

@Component({
    selector:'app-userquestdashboard',
    templateUrl:'./userQuestDashboard.component.html',
    styleUrls:['./userQuestDashboard.component.css']
})

export class UserQuestDashboard{
    constructor(){}
}