import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Department } from "src/app/models";

const { baseUrl } = environment;

@Injectable({
  providedIn:'root'
})

export class DepartmentService {
  constructor(private http: HttpClient) {}

  getAll = (parameters: string): Observable<Department> => this.http.get<Department>(`${baseUrl}/Departments?CustomerId=${parameters}`);

  get = (id: string): Observable<Department> => this.http.get<Department>(`${baseUrl}/Departments/${id}`);

  save = (data: Department): Observable<Department> => data.id
    ? this.http.put<Department>(`${baseUrl}/Departments/${data.id}`, data)
    : this.http.post<Department>(`${baseUrl}/Departments`, data);

  delete = (id: string): Observable<Department> => this.http.delete<Department>(`${baseUrl}/Departments/${id}`);
}
