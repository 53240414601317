import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const {baseUrl}=environment;

@Injectable({
  providedIn:'root'
})

export class FormSectionsService{
  constructor(private http:HttpClient){}

  getAll(id): Observable<any> {
    return this.http.get(`${baseUrl}/formsSections/GetList/${id}`);
  }

  get(id):Observable<any>{
    return this.http.get(`${baseUrl}/formsSections/${id}`);
  }

  create(data): Observable<any> {
    return this.http.post(`${baseUrl}/formsSections`, data);
  }

  update(id, data): Observable<any> {
    return this.http.put(`${baseUrl}/formsSections/${id}`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${baseUrl}/formsSections/${id}`);
  }
}