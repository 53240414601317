import { Component, Input } from "@angular/core";

@Component({
    selector:"app-loader1",
    templateUrl:"./loader1.component.html",
    styleUrls:["./loader1.component.css"]
})

export class Loader1Component{
    @Input() isShowing:boolean;

    constructor(){
    }
}