import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from "@angular/material";
import { FormSectionInterface } from "src/app/models/formSection";
import { FormSectionsService } from "src/app/services/forms/formSection.service";
import { UtilsService } from "src/app/services/utils/utils.service";

@Component({
  selector:'app-formSection',
  templateUrl:'./formSection.component.html',
  styleUrls:['./formSection.component.css']
})

export class FormSectionComponent implements OnInit{
  @Input() formId:any;
  formSections:FormSectionInterface[]=[];
  formSection:any;

  constructor(
    private formSectionsService:FormSectionsService,
    public utilsService:UtilsService,
    public dialog: MatDialog
  ){
  }

  ngOnInit(){
      if(this.formId!='new')
      {
        this.retrieveFormSections();
      }
  }

  retrieveFormSections(){
    this.formSectionsService.getAll(this.formId)
    .subscribe(
      data=>{
        this.formSections=data;
      },
      error=>{
        console.log(error);
      }
    );
  }

  OnSave(){
    let model:FormSectionInterface=this.formSection.value;
    model['idForm']=this.formId;
    console.log(model);

    this.formSectionsService.create(model)
      .subscribe(
        data=>{
          this.utilsService.showMessage("Seccion Guardada","Correcto");
          this.retrieveFormSections();
        },
        error=>{
          this.utilsService.showMessage("Error al guardar la seccion","Error");
          console.log(error);
        }
      );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FormSectionDialog, {
      width: '450px',
      height:'450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!==undefined){
        this.formSection=result;
        this.OnSave();
      }
    });
  }
}

@Component({
  selector: 'formSectionDialog',
  templateUrl: 'formSectionDialog.html',
})
export class FormSectionDialog {
  
  formSection=new FormGroup({
    name: new FormControl('',Validators.required),
    description:new FormControl(''),
    orden:new FormControl(1),
    status:new FormControl(true),
  });

  constructor(
    public dialogRef: MatDialogRef<FormSectionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}