import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SepomexInterface } from "src/app/models/sepomex";
import { environment } from "src/environments/environment";

const {baseUrl}=environment;

@Injectable({
  providedIn:'root'
})

export class SepomexService{
  constructor(private http:HttpClient){}

  getByCP =(CP:string):Observable<any> =>this.http.get<any>(`${baseUrl}/Sepomex?cp=${CP}`);

  get = (id: string): Observable<any> => this.http.get<SepomexInterface>(`${baseUrl}/Sepomex/${id}`);

  getByKey = (key:string): Observable<SepomexInterface> => this.http.get<SepomexInterface>(`${baseUrl}/Sepomex/key?key=${key}`);
  
}