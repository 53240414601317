import { Component, ContentChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { LoginInterface } from "src/app/models/login";
import { relPersonTestService } from "src/app/services/cuestionarios/relPersonTest.service";
import { AutenticationService } from "src/app/services/Login/autentication.service";
import { LoginService } from "src/app/services/Login/login.service";
import { UtilsService } from "src/app/services/utils/utils.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls:['./login.component.css'],
})
export class LoginComponent{
  idRol:string;
  isShowing:boolean=false;

  loginForm=new FormGroup({
    userName: new FormControl('',Validators.required),
    password:new FormControl('',Validators.required),
  });

  constructor(
    public authenticationService:AutenticationService,
    private router:Router,
    private loginService:LoginService,
    private _snackBar:MatSnackBar,
    public _relPersonTest:relPersonTestService,
    public utilsService:UtilsService)
  {}

  loaddingMenu(idRol:string){
    this.authenticationService.loadMenu(idRol);
  }

  login(){
    let urlStr:string="";
    let model:LoginInterface=this.loginForm.value;
    let idRol:string;
    this.isShowing=true;
    this.loginService.post(model)
      .subscribe(
        data=>{
          if(data!=null){
            this.isShowing=false;
            this.authenticationService.email=data.email;
            this.authenticationService.userName=data.usuario;
            this.authenticationService.idUser=data.idUser;
            
            sessionStorage.setItem("X-SYMASHEADER",data.token);
            sessionStorage.setItem("X-SYMASUSER",JSON.stringify(data));

            data.listUserRoles.forEach(function(value) {
              switch (value['idRol'].toUpperCase()) {
                case "E4DB2AAC-7BDF-47B2-A212-5F823A068F3B":
                  idRol=value['idRol'];
                  urlStr='/userCuestionario';
                  break;
                default:
                  idRol=value['idRol'];
                  urlStr='/forms';
                  break;
              }
            });

            this.authenticationService.loadMenu(idRol);
            this.router.navigate([`${urlStr}`]);
          }
          else{
            this.utilsService.showMessage("Usuario / Password - Erroneos","Revisar");  
            this.isShowing=false;
          }
        },
        error=>{
          this.utilsService.showMessage("Usuario / Password - Erroneos","Revisar");
        }
      );
  }
}