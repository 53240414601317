import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Person } from "src/app/models/Person.Interface";
import { environment } from "src/environments/environment";

const { baseUrl } = environment;

@Injectable({
  providedIn:'root'
})

export class PersonService{
  constructor(private http: HttpClient ){}

  getAll = () : Observable<Person> => this.http.get<Person>(`${baseUrl}/Peoples`);

  get = (id: string): Observable<Person> => this.http.get<Person>(`${baseUrl}/Peoples/${id}`);

  save = (data: Person): Observable<Person> => data.id
    ? this.http.post<Person>(`${baseUrl}/Peoples/${data.id}`, data)
    : this.http.post<Person>(`${baseUrl}/Peoples`, data);

  delete = (id: string): Observable<Person> => this.http.delete<Person>(`${baseUrl}/Peoples/${id}`);
}
