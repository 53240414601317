import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Phone } from 'src/app/models';

const { baseUrl } = environment;

@Injectable({
  providedIn: 'root'
})
export class PhonesService {
  constructor(private http: HttpClient) { }

  getAll = (personId: string) : Observable<Phone> => this.http.get<Phone>(`${baseUrl}/phones?peopleId=${personId}`);

  get = (id: string): Observable<Phone> => this.http.get<Phone>(`${baseUrl}/phones/${id}`);

  save = (data: Phone): Observable<Phone> => data.id
    ? this.http.put<Phone>(`${baseUrl}/phones/${data.id}`, data)
    : this.http.post<Phone>(`${baseUrl}/phones`, data);

  //TODO: Delete code line 24 and incomment code line
  delete = (id: string): Observable<Phone> => this.http.post<Phone>(`${baseUrl}/phones/delete/${id}`, id);
  // delete = (id: string): Observable<Phone> => this.http.delete<Phone>(`${baseUrl}/phones/${id}`);
}
