import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const {baseUrl} =environment

@Injectable({
  providedIn:'root'
})

export class AffiliateCustomerService{
  constructor(private http:HttpClient){}

  getAll = ():Observable<any> => this.http.get(`${baseUrl}/Clientes_Filiales`);

  get =(id):Observable<any> => this.http.get(`${baseUrl}/Clientes_Filiales/${id}`);

  create(data): Observable<any> {
    return this.http.post(`${baseUrl}/Clientes_Filiales`, data);
  }

  update(id, data): Observable<any> {
    return this.http.put(`${baseUrl}/Clientes_Filiales/${id}`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${baseUrl}/Clientes_Filiales/${id}`);
  }
}