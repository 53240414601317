import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CueCuestionarioRespuestaInterface } from "src/app/models/cueCuestionarioRespuesta";
import { RelPersonTestInterface } from "src/app/models/relPersonTest";
import { environment } from "src/environments/environment";

const {baseUrl}=environment;

@Injectable({
  providedIn:"root"
})

export class UserQuestionnaireService{
  constructor(private http:HttpClient){}

  get= (person:string,form:number):Observable<CueCuestionarioRespuestaInterface> => this.http.get<CueCuestionarioRespuestaInterface>(`${baseUrl}/UserQuestionnaire?person=${person}&form=${form}`);

  post = (idPerson:string,data: CueCuestionarioRespuestaInterface): Observable<CueCuestionarioRespuestaInterface> => this.http.post<CueCuestionarioRespuestaInterface>(`${baseUrl}/UserQuestionnaire?person=${idPerson}`,data);

  put = (idPerson:string,data: CueCuestionarioRespuestaInterface): Observable<CueCuestionarioRespuestaInterface> => this.http.post<CueCuestionarioRespuestaInterface>(`${baseUrl}/UserQuestionnaire/${idPerson}`,data);

  inactivar = (idPerson:string,customerId:string,flag:boolean,data:any):Observable<RelPersonTestInterface> => this.http.post<RelPersonTestInterface>(`${baseUrl}/UserQuestionnaire/inactivar?person=${idPerson}&customer=${customerId}&status=${flag}`,data);
}