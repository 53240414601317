import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";

@Injectable({
  providedIn:"root"
})

export class UtilsService{
  constructor(private snackBar:MatSnackBar){}

  showMessage(message:string,action:string){
    this.snackBar.open(message,action,{duration:3000});
  }
}