import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PersonInfo } from "src/app/models/PersonInfo.Interface";

const { baseUrl } = environment;

@Injectable({
  providedIn:'root'
})
export class PersonInfoService{
  constructor(private http: HttpClient){}

  getAll = () : Observable<PersonInfo> => this.http.get<PersonInfo>(`${baseUrl}/PersonInfo`);

  get = (id: string): Observable<PersonInfo> => this.http.get<PersonInfo>(`${baseUrl}/PersonInfo/${id}`);

  save = (person:string,data: PersonInfo): Observable<PersonInfo> => data.idPersona
    ? this.http.post<PersonInfo>(`${baseUrl}/PersonInfo/${data.idPersona}`, data)
    : this.http.post<PersonInfo>(`${baseUrl}/PersonInfo?person=${person}`, data);

  delete = (id: string): Observable<PersonInfo> => this.http.delete<PersonInfo>(`${baseUrl}/PersonInfo/${id}`);
}
