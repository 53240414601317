import { Component, OnInit } from '@angular/core';
import { AutenticationService } from './services/Login/autentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls:['./app.component.css','../tailwind.css']
})
export class AppComponent implements OnInit {
  panelOpenState = false;
  headerItems: Object[] = [];
  title = 'app';

  constructor(public authenticationService:AutenticationService){
  }

  ngOnInit(){
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    document.getElementById("btnSideNav").style.visibility = "visible";
  }
}
