import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RelPersonaNumempleado } from "src/app/models/RelPersonaNumEmpleado.interface";
import { environment } from "src/environments/environment";

const { baseUrl } = environment;

@Injectable({
    providedIn:'root'
})

export class RelPersonNumEmpleadoService{
    constructor(private http:HttpClient){}

    post = (data:RelPersonaNumempleado) : Observable<RelPersonaNumempleado> =>
    this.http.post<RelPersonaNumempleado>(`${baseUrl}/RelPersonaNumEmpleado`, data);

    put =(data:RelPersonaNumempleado) : Observable<RelPersonaNumempleado> =>
    this.http.post<RelPersonaNumempleado>(`${baseUrl}/RelPersonaNumEmpleado/${data.idPersona}`, data);
}