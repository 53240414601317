import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserInterface } from "src/app/models/user";
import { UserChangePasswordInterface } from "src/app/models/viewModels/UserChangePasswordModel";
import { environment } from "src/environments/environment";

const {baseUrl}=environment;

@Injectable({
  providedIn: 'root'
})

export class UserService{
  constructor(private http:HttpClient){}

  getAll = () : Observable<UserInterface> => this.http.get<UserInterface>(`${baseUrl}/Users`);

  get = (id: number): Observable<UserInterface> => this.http.get<UserInterface>(`${baseUrl}/Users/${id}`);

  save = (data: UserInterface): Observable<UserInterface> => data.idUser != ''
    ? this.http.post<UserInterface>(`${baseUrl}/Users/${data.idUser}`, data)
    : this.http.post<UserInterface>(`${baseUrl}/Users`, data);

  changePassword = (data:UserChangePasswordInterface): Observable<UserInterface> => this.http.post<UserInterface>(`${baseUrl}/Users/changePassword`, data);

  delete = (idUser: string): Observable<UserInterface> => this.http.post<UserInterface>(`${baseUrl}/Users/${idUser}`,null);
}