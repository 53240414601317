import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const { baseUrl } = environment;

@Injectable({
  providedIn:'root'
})
export class CatalogsService{
  constructor(private http:HttpClient){}

  getCategories = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getCategories`);

  getFormularioSectionItem = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getFormulariosSectionsItems`);

  getForms = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getForms`);

  getProviderTypes = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getProviderTypes`);

  getPeopleTypes = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getPeopleTypes`);

  getSpecialities = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getSpecialities`);

  getGenders = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getGenders`);

  getNacionalidades = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getNacionalidades`);

  getEdosCiviles = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getEstadoCivil`);

  getReligiones = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getReligiones`);

  getEstados = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getEstados`);

  getNivelesEducativos = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getNivelesEducativos`);

  getMailTypes = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getMailTypes`);

  getPhoneTypes = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getPhoneTypes`);

  getCustomers = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getCustomers`);

  getQuestionnaires = (): Observable<any> => this.http.get(`${baseUrl}/catalogs/getQuestionnaires`);
}
