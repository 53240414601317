import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { Form } from "src/app/models/Form.Interface";
import { FormsService } from "src/app/services/forms/forms.service";
import { LayoutService } from "src/app/services/layouts/layouts.service";

@Component({
  selector:"app-forms",
  templateUrl:"./forms.component.html",
  styleUrls:["./forms.component.css"]
})

export class FormsComponent implements OnInit,AfterViewInit {
  forms:Form[]=[];
  dataSource:any;

  displayedColumns: string[] = [
    'id'
    ,'name'
    ,'description'
    ,'dateAdd'
    ,'status'
  ];

  constructor(
    public layoutService:LayoutService,
    private formsService:FormsService,
    private router:Router)
  {
    layoutService.title="Forms";
  }

  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;

  ngAfterViewInit(){
    this.retrieveForms();
  }

  ngOnInit(){
  this.retrieveForms();
  }

  retrieveForms=()=>{
    this.formsService.getAll()
    .subscribe(
      data=>{
        this.forms=data;
        this.dataSource=new MatTableDataSource<Form>(data);
        this.dataSource.paginator=this.paginator;
      },
      error=>{
        console.log(error);
      }
    );
  }

  getRecordRow(row){
    console.log(row);
    this.router.navigate([`/newForm/${row.id}`]);
  }
}
