import { Component } from '@angular/core';
import { LayoutService } from 'src/app/services/layouts/layouts.service';

@Component({
  selector: 'app-simplePage',
  templateUrl: './simplePage.component.html',
  styleUrls:['./simplePage.component.css']
})
export class SimplePageComponent {
  title:string="Header Test"

  constructor(public layoutService:LayoutService){}
}