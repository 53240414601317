import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormMenuTabInterface } from "src/app/models/formMenuTab";
import { FormMenuTabService } from "src/app/services/forms/formMenuTab.service";
import { UtilsService } from "src/app/services/utils/utils.service";

@Component({
  selector:"app-formMenuTab",
  templateUrl:"./formMenuTab.component.html",
  styleUrls:["./formMenuTab.component.css"]
})

export class FormMenuTabComponent implements OnInit{
  @Input() formId:any;
  formMenuTabs:FormMenuTabInterface[]=[];
  formMenuTab:any;

  constructor(
    public utilsService:UtilsService,
    private formMenuTabService:FormMenuTabService,
    public dialog: MatDialog){}

  ngOnInit(){
    if(this.formId!='new')
      {
        this.retrieveFormMenuTabs();
      }
  }

  retrieveFormMenuTabs(){
    this.formMenuTabService.getAll(this.formId)
      .subscribe(
        data=>{
          this.formMenuTabs=data;
        },
        error=>{
          console.log(error);
        }
    );
  }

  OnSave(){
    let model:FormMenuTabInterface=this.formMenuTab.value;
    model['idForm']=this.formId;

    this.formMenuTabService.create(model)
      .subscribe(
        data=>{
          this.utilsService.showMessage("Menu Tab guardado","Correcto");
          this.retrieveFormMenuTabs();
        },
        error=>{
          this.utilsService.showMessage("Error al guardar el menu","Error");
          console.log(error);
        }
      );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FormMenuTabDialog, {
      width: '450px',
      height:'450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result!==undefined){
        this.formMenuTab=result;
        this.OnSave();
      }
    });
  }

  openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }
}

@Component({
  selector: 'formMenuTabDialog',
  templateUrl: 'formMenuTabDialog.html',
})
export class FormMenuTabDialog {
  
  formMenuTab=new FormGroup({
    name: new FormControl('',Validators.required),
    description:new FormControl(''),
    orden:new FormControl(1),
    status:new FormControl(true),
  });

  constructor(
    public dialogRef: MatDialogRef<FormMenuTabDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}