import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Mail } from 'src/app/models';

const { baseUrl } = environment;

@Injectable({
  providedIn: 'root'
})
export class MailsService {
  constructor(private http: HttpClient) { }

  getAll = (personId: string) : Observable<Mail> => this.http.get<Mail>(`${baseUrl}/mails?peopleId=${personId}`);

  get = (id: string): Observable<Mail> => this.http.get<Mail>(`${baseUrl}/mails/${id}`);

  save = (data: Mail): Observable<Mail> => data.id
    ? this.http.put<Mail>(`${baseUrl}/mails/${data.id}`, data)
    : this.http.post<Mail>(`${baseUrl}/mails`, data);

  //TODO: Delete code line 24 and incomment code line
  delete = (id: string): Observable<Mail> => this.http.post<Mail>(`${baseUrl}/mails/delete/${id}`, id);
  // delete = (id: string): Observable<Mail> => this.http.delete<Mail>(`${baseUrl}/mails/${id}`);
}
