import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent, UserChangePasswordDialog } from './nav-menu/nav-menu.component';
import { PageBlankComponent } from './layouts/pageBlank/pageBlank.component';
import { SimplePageComponent } from './layouts/pageSimple/simplePage.component';
import { PageCardedComponent } from './layouts/pageCarded/pageCarded.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { ProductsListComponent } from './pages/product/productsList.component';
import { NewProductComponent } from './pages/product/newProduct.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsComponent } from './pages/forms/forms.component';
import { FormSectionComponent, FormSectionDialog } from './pages/forms/components/formSection.component';
import { FormSectionItemComponent, FormSectionItemDialog, ItemValueOptionDialog } from './pages/forms/components/formSectionItem.component';
import { NewFormComponent } from './pages/forms/newForm.component';
import { FormMenuTabComponent, FormMenuTabDialog } from './pages/forms/components/formMenuTab.component';

import { ListPersonTest, NewPersonTestDialog } from './pages/cuestionarios/programs/listPersonTest.component';

import { DemoMaterialModule } from './material-module';
import { LoginComponent } from './pages/login/login.component';

import { CueTemplateComponent } from './pages/examples/cueTemplate/cueTemplate.component';
import { UserCuestionarioComponent, UserQuestionnareDialog } from './pages/user/userCuestionario.component';
import { ImportUserModalFormComponent } from './pages/cuestionarios/programs/import-users/import-user-modal-form/import-user-modal-form.component';
import { Loader1Component } from './components/loaders/loader1/loader1.component';
import { Loader2Component } from './components/loaders/loader2/loader2.component';

import { AgmCoreModule } from '@agm/core';
import { UserQuestDashboard } from './pages/user/userQuestDashboard.component';

/*Routes*/
const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'home', component: HomeComponent },
  { path: 'counter', component: CounterComponent },
  { path: 'fetch-data', component: FetchDataComponent },
  {
    path: 'providers',
    loadChildren: () => import('./pages/proveedor/proveedor.module').then(m => m.ProveedorModule)
  },
  {
    path: 'mailConfigurationCompanies',
    loadChildren: () => import('./pages/mail-configuration-company/mail-configuration-company.module').then(m => m.MailConfigurationCompanyModule)
  },
  { path: 'products', component: ProductsListComponent },
  { path: 'newProduct/:id', component: NewProductComponent },
  { path: 'forms', component: FormsComponent },
  { path: 'newForm/:id', component: NewFormComponent },
  {
    path: 'questionnaires',
    loadChildren: () => import('./pages/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule)
  },
  { path: 'userCuestionario', component: UserCuestionarioComponent},
  { path: 'userQuestDashboard', component: UserQuestDashboard},
  { path: 'personTestList/:customerId/:id', component:ListPersonTest},

  { path: 'example/cueTemplate/:id', component: CueTemplateComponent }
];
/*Routes*/

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    PageBlankComponent,
    SimplePageComponent,
    PageCardedComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    ProductsListComponent,
    NewProductComponent,
    FormsComponent,
    NewFormComponent,
    FormMenuTabComponent,
    FormMenuTabDialog,
    FormSectionComponent,
    FormSectionDialog,
    FormSectionItemComponent,
    FormSectionItemDialog,
    ItemValueOptionDialog,
    CueTemplateComponent,
    UserCuestionarioComponent,
    UserQuestionnareDialog,
    UserQuestDashboard,
    ListPersonTest,
    NewPersonTestDialog,
    ImportUserModalFormComponent,
    Loader1Component,
    Loader2Component,
    UserChangePasswordDialog
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    DemoMaterialModule,
    AgmCoreModule.forRoot({apiKey: "AIzaSyDXnLzeDuz0AEMFciq3FuZ5l-0Y1tJYwog"})
  ],
  providers: [
    //ProveedoresService
  ],
  /*Ini Se utiliza para agregar las llamadas a los dialogs*/
  entryComponents: [
    FormMenuTabComponent,
    FormMenuTabDialog,
    FormSectionComponent,
    FormSectionDialog,
    FormSectionItemComponent,
    FormSectionItemDialog,
    ItemValueOptionDialog,
    ListPersonTest,
    NewPersonTestDialog,
    ImportUserModalFormComponent,
    UserCuestionarioComponent,
    UserQuestionnareDialog,
    NavMenuComponent,
    UserChangePasswordDialog
  ],
  /*Fin*/
  bootstrap: [AppComponent]
})
export class AppModule { }
