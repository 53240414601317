import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatPaginator, MatTableDataSource, MatSort } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { AffiliateCustomerInterface } from "src/app/models/affiliateCustomer";
import { NewPersonTestInterface } from "src/app/models/newPersonTestInterface";
import { RelPersonTestInterface } from "src/app/models/relPersonTest";
import { AffiliateCustomerService } from "src/app/services/clients/affiliateCustomer.service";
import { relPersonTestService } from "src/app/services/cuestionarios/relPersonTest.service";
import { LayoutService } from "src/app/services/layouts/layouts.service";
import { UserQuestionnaireService } from "src/app/services/user/userQuestionnaire.service";
import { UtilsService } from "src/app/services/utils/utils.service";
import { ImportUserModalFormComponent } from "./import-users/import-user-modal-form/import-user-modal-form.component";

@Component({
  selector:"app-listPersonTest",
  templateUrl:"./listPersonTest.component.html",
  styleUrls:["./listPersonTest.component.css"]
})

export class ListPersonTest implements OnInit, AfterViewInit{
  IdTest:any;
  dataSource:any;

  displayedColumns: string[] = [
    'nombre'
    ,'usuario'
    //,'filial'
    ,'inicio'
    ,'fin'
    ,'status'
    ,'inactive'
  ];

  constructor(
    public utilsService:UtilsService,
    public layoutService:LayoutService,
    private _relPersonTestService:relPersonTestService,
    private userCuestionnaireService:UserQuestionnaireService,
    private router:Router,
    private route:ActivatedRoute,
    public dialog:MatDialog
  ){}

  @ViewChild(MatPaginator,{static:true}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;

  ngAfterViewInit(){
    this.IdTest=this.route.snapshot.paramMap.get('id');
    this.retrieveRelPersonTestList();
  }

  ngOnInit(){
  }

  retrieveRelPersonTestList=()=>{
    this._relPersonTestService.getAll(this.IdTest)
    .subscribe(
      data=>{
        let list:RelPersonTestInterface[]=[];

        data.forEach(function (value) {
          let model:RelPersonTestInterface={
            idPersona:value["idPersona"],
            nombre:`${value['person']['name']} ${value['person']['paternal']} ${value['person']['maternal']}`,
            usuario:value['user']['usuario'],
            inicio:value['fecha'],
            fin:value['fechaTermina'],
            status:value['status']
          };

          list.push(model);
        });

        this.dataSource=new MatTableDataSource<RelPersonTestInterface>(list);
        this.dataSource.paginator=this.paginator;
      },
      error=>{
        console.log(error);
      }
    )
  }

  openDialog():void{
    const dialogRef = this.dialog.open(NewPersonTestDialog, {
      width: '550px',
      height:'550px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!==undefined){
        let model:NewPersonTestInterface=result.value;
        model["idCueVersionCuestionario"]=Number(this.IdTest);

        this._relPersonTestService.create(model)
        .subscribe(
          data=>{
            this.utilsService.showMessage("Usuario Guardado","Correcto");
            this.retrieveRelPersonTestList();
          },
          error=>{
            this.utilsService.showMessage("Error al guardar el usuario","Error");
          }
        );
      }
    });
  }

  openImportUsersDialog = (): void => {
    const dialogRef = this.dialog.open(ImportUserModalFormComponent, {
      width: '550px',
      height:'200px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(file => {
      if(file !== undefined ) {
        let formData = new FormData();

        formData.append("questionnaireSettingId", this.IdTest);
        formData.append("file", file);

        this._relPersonTestService.importUsers(formData)
          .subscribe(
            () => {
              this.utilsService.showMessage("Usuarios Importados", "Correcto");
              this.retrieveRelPersonTestList();
            },
            (error: any) => {
              this.utilsService.showMessage(`Error al importar los usuario: ${error}`, "Error");
            }
          )
      }
    });
  };

  onDesactivar(evt:Event,elm:any){
    this.userCuestionnaireService.inactivar(elm["idPersona"],this.route.snapshot.paramMap.get('customerId'),evt["checked"],null).subscribe(
      data=>{
        this.utilsService.showMessage("Registro Actualizado","Correcto");
        this.retrieveRelPersonTestList();
      },
      error=>{
        this.utilsService.showMessage("Error al Actualizar","Error");
      }
    );
  }
}

@Component({
  selector:'newPerosnTestDialog',
  templateUrl:'newPersonTestDialog.html'
})

export class NewPersonTestDialog implements OnInit{
  affiliateCustomerList:AffiliateCustomerInterface[]=[];

  NewPersonTestForm=new FormGroup({
    email: new FormControl('',Validators.required),
    nombre: new FormControl('',Validators.required),
    paterno: new FormControl('',Validators.required),
    materno: new FormControl('',Validators.required),
    idFilial: new FormControl(''),
  });

  constructor(
    private affiliateCustomerService: AffiliateCustomerService,
    public dialogRef:MatDialogRef<NewPersonTestDialog>,
    @Inject(MAT_DIALOG_DATA) public data:any){}

    ngOnInit(){
      this.retrieveAffiliateCustomers();
    }

    retrieveAffiliateCustomers(){
      this.affiliateCustomerService.getAll()
      .subscribe(
        data => {
          this.affiliateCustomerList=data;
        },
        error => {
          console.log(error);
        }
      );
    }

    onNoClick(): void {
      this.dialogRef.close();
    }
}
