import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import {
  CatalogInterface,
  Person,
  PersonInfo,
  Phone,
  Mail,
  Customer,
  Department,
  Job
} from "src/app/models";
import { AddressInterface } from "src/app/models/address";
import { AffiliateCustomerInterface } from "src/app/models/affiliateCustomer";
import { Coordenada } from "src/app/models/coordenada";
import { MouseEvent } from '@agm/core';
import { CueCuestionarioRespuestaInterface } from "src/app/models/cueCuestionarioRespuesta";
import { RespuestaInterface } from "src/app/models/respuesta";
import { SepomexInterface } from "src/app/models/sepomex";
import { AddressService } from "src/app/services/addresses/address.service";
import { SepomexService } from "src/app/services/addresses/sepomex.service";
import { CatalogsService } from "src/app/services/catalogs/catalogs.service";
import { AffiliateCustomerService } from "src/app/services/clients/affiliateCustomer.service";
import { ClientService } from "src/app/services/clients/client.service";
import { relPersonTestService } from "src/app/services/cuestionarios/relPersonTest.service";
import { DepartmentService } from "src/app/services/departments/department.service";
import { FormsService } from "src/app/services/forms/forms.service";
import { LayoutService } from "src/app/services/layouts/layouts.service";
import { MailsService } from "src/app/services/person/mail.service";
import { PersonService } from "src/app/services/person/person.service";
import { PersonInfoService } from "src/app/services/person/personInfo.service";
import { PhonesService } from "src/app/services/person/phone.service";
import { PuestoService } from "src/app/services/puesto/puesto.service";
import { UserQuestionnaireService } from "src/app/services/user/userQuestionnaire.service";
import { CalculateCURPService } from "src/app/services/utils/calculateCurp.service";
import { UtilsService } from "src/app/services/utils/utils.service";
import { AutenticationService } from "src/app/services/Login/autentication.service";
import { RelPersonNumEmpleadoService } from "src/app/services/person/relPersonNumEmpleado.service";
import { RelPersonaNumempleado } from "src/app/models/RelPersonaNumEmpleado.interface";

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-userCuestionario',
  templateUrl: './userCuestionario.component.html',
  styleUrls: ['/userCuestionario.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class UserCuestionarioComponent implements OnInit
{
  array:boolean[]=[];
  arrayClass:string[]=[];

  disabledItems:boolean=false;
  IdGender:number;
  Edad:Number;
  IdPerson:string;
  NumEmpleado:string;
  IdAddress:string;
  IdPersonInfo:string;

  IdFilial:any;
  listAffiliateCustomer:AffiliateCustomerInterface[]=[];
  IdClient:any;
  listClients:Customer[]=[];

  optionValueAnt:string="";
  optionValueAntNivel2:string="";

  IdForm: any;
  IdCuestionario:any;
  Respuestas:any;
  tabs: Array<any>;
  sections: Array<any>;
  items: Array<any>;

  personalInfo=new FormGroup({
    idClient:new FormControl(),
    idFilial:new FormControl(),

    name:new FormControl('',Validators.required),
    paternal:new FormControl('',Validators.required),
    maternal:new FormControl('',Validators.required),

    dateBirth:new FormControl(moment(),Validators.required),
    stateId:new FormControl('0'),
    nacionalityId:new FormControl('0'),

    genderId:new FormControl('0'),
    maritalStatusId:new FormControl('0'),
    religionId:new FormControl('0'),
    CURP:new FormControl(''),

    numEmpleado:new FormControl('')
  });

  addressForm=new FormGroup({
    calle:new FormControl(""),
    numExterior:new FormControl(""),
    numInterior:new FormControl(""),
    entreCalle1:new FormControl(""),
    entreCalle2:new FormControl(""),
    CP:new FormControl(""),
    d_estado:new FormControl({value:"NO SELECCIONADO",disabled:true}),
    d_mnpio:new FormControl({value:"NO SELECCIONADO",disabled:true}),
    codSepomex:new FormControl("0"),
    latitud:new FormControl(),
    longitud:new FormControl()
  });

  laboralForm=new FormGroup({
    numHijos:new FormControl(0),
    idNivelEducativo:new FormControl(1),
    idDepartamento:new FormControl(),
    idPuesto:new FormControl(),
    antTrabajoIni:new FormControl(new Date()),
    antTrabajoFin:new FormControl(new Date()),
    // antTrabajoFin:new FormControl({value: new Date(), disabled: true}),
    antPuestoIni:new FormControl(new Date()),
    antPuestoFin:new FormControl(new Date())
  });

  form: FormGroup;

  nacionalidadItems:CatalogsService[]=[];
  generoItems:CatalogsService[]=[];
  estadoCivilItems:CatalogsService[]=[];
  religionItems:CatalogsService[]=[];
  nivelEducativoItems:CatalogsService[]=[];
  phoneTypes: CatalogInterface[] = [];
  mailTypes: CatalogInterface[] = [];

  departmentItems:Department[]=[];
  puestoItems:Job[]=[];

  edosItems:CatalogsService[]=[];
  sepomexItems:SepomexInterface[]=[];

  phoneForm: FormGroup;
  mailForm: FormGroup;

  phones: Phone[];
  mails: Mail[];

  constructor(
    public utilsService:UtilsService,
    public authenticationService:AutenticationService,
    public layoutService: LayoutService,
    private personInfoService:PersonInfoService,
    private personService:PersonService,
    private addressService:AddressService,
    private route: ActivatedRoute,
    private catalogs:CatalogsService,
    private clientService:ClientService,
    private affiliateCustomerService:AffiliateCustomerService,
    private departmentService:DepartmentService,
    private puestoService:PuestoService,
    private sepomexService:SepomexService,
    private formService: FormsService,
    private _relPersonTest:relPersonTestService,
    private calculateCurpService:CalculateCURPService,
    private userQuestionnaireService:UserQuestionnaireService,
    private mailServices: MailsService,
    private phoneServices: PhonesService,
    private relPersonNumempleadoService: RelPersonNumEmpleadoService,
    private fb: FormBuilder,
    private dialog:MatDialog
  )
  {
    layoutService.title = "SECCIONES";

    this.phoneForm = this.initPhoneForm();
    this.mailForm = this.initMailForm();
  }

  ngOnInit(){
    this.arrayClass.push("tablinks gradient-button gradient-button-3");
    this.array.push(true);
    this.retrieveCatalogs();
    this.retrievePersonTest();
    this.getLocation();
  }

  OnSave(status:string,tabId:any){
    this.form.controls[`menuTab${tabId}`].setValue(true);
    let listRespuesta:RespuestaInterface[]=[];
    let model:CueCuestionarioRespuestaInterface={
      idForm:Number(this.IdForm),
      idCuestionario:Number(this.IdCuestionario),
      idTab:Number(tabId),
      status:status,
      respuestas:listRespuesta
    };

    this.tabs.forEach(tab=>{
      let respTabModel:RespuestaInterface={
        columnName:`menuTab${tab.id}`,
        respuesta:this.form.controls[`menuTab${tab.id}`].value
      };
      model.respuestas.push(respTabModel);

      tab.secctions.forEach(sec => {
        sec.items.forEach(item => {
          let respuestaModel:RespuestaInterface={
            columnName:item.name,
            respuesta:this.form.controls[item.name].value
          };
          model.respuestas.push(respuestaModel);
        });
      });
    });

    if(this.Respuestas!=null && this.Respuestas.length>0){
      console.log("Put");
      let count=0;
      if(status=='T'){
        if(this.array.length<22)
        {
          this.form.controls[`menuTab${tabId}`].setValue(false);
          this.utilsService.showMessage("Debes Contestar Previamente Las Secciones Anteriores","Precaucion");
          return;
        }
        else{
          this.array.forEach(section=>{
            if(!section){
              ++count;
            }
          });

          if(count>1){
            this.form.controls[`menuTab${tabId}`].setValue(false);
            this.utilsService.showMessage("Debes Contestar Previamente Las Secciones Anteriores","Precaucion");
          return;
          }
        }
      }

      this.userQuestionnaireService.put(this.IdPerson,model)
      .subscribe(
        data=>{
          if(data["message"]!=null && data["message"]!=""){
            this.utilsService.showMessage(data["message"],"Error");
            return;  
          }
          else{
            if(status=='T')
            {
              this.utilsService.showMessage("Gracias Por Contestar Nuestro Cuestionario","Cerrando Sesión");
              this.array[Number(tabId)]=true;
              this.onMenuDisabled(tabId);
              this.onDisabled(status);
              setTimeout(()=>{
                this.authenticationService.logout();
              }, 3000);
            }else{
              this.utilsService.showMessage("Cuestionario Actualizado","Correcto");
              this.array[Number(tabId)]=true;
              this.onMenuDisabled(tabId);
              this.onDisabled(status);
            }
          }
        },
        error=>{
          this.utilsService.showMessage("Error al actualizar el cuestionario","Error");
        }
      );
    }
    else{
      console.log("Post");
      let countPost=0;
      if(status=='T'){
        if(this.array.length<22)
        {
          this.form.controls[`menuTab${tabId}`].setValue(false);
          this.utilsService.showMessage("Debes Contestar Previamente Las Secciones Anteriores","Precaucion");
          return;
        }
        else{
          this.array.forEach(section=>{
            if(!section){
              ++countPost;
            }
          });

          if(countPost>1){
            this.form.controls[`menuTab${tabId}`].setValue(false);
            this.utilsService.showMessage("Debes Contestar Previamente Las Secciones Anteriores","Precaucion");
          return;
          }
        }
      }
      
      this.userQuestionnaireService.post(this.IdPerson,model)
      .subscribe(
        data=>{
          if(data["message"]!=null && data["message"]!=""){
            this.utilsService.showMessage(data["message"],"Error");
            return;  
          }
          else{
            this.Respuestas=data["data"]["respuestas"];
            this.array[Number(tabId)]=true;
            this.onMenuDisabled(tabId);
            this.utilsService.showMessage("Cuestionario Guardado","Correcto");
            this.onDisabled(status);
          }
        },
        error=>{
          this.utilsService.showMessage("Error al guardar el cuestionario","Error");
        }
      );
    }

  }

  OnSaveGeneralInfo(){
    this.OnSavePerson();
    this.OnSaveAddresses();
    this.OnSavePersonInfo();
    this.OnSaverelPersonNumEmpleado();
    this.utilsService.showMessage("Guardado Correcto","Correcto");
  }

  OnSavePerson(){
    let model:Person=this.IdPerson
    ? {
      ...this.personalInfo.value,
      id:this.IdPerson
    }
    : this.personalInfo.value

    this.personService.save(model)
    .subscribe(
      data=>{
        console.log(data);
      },
      error=>{console.log(error);}
    );
  }

  OnSaveAddresses(){
    let model:AddressInterface=this.IdAddress
    ? {
      ...this.addressForm.value,
      id:this.IdAddress
    }
    : this.addressForm.value

    this.addressService.save(this.IdPerson,model)
    .subscribe(
      data=>{
        console.log(data);
      },
      error=>{console.log(error);}
    );
  }

  OnSavePersonInfo(){
    let model:PersonInfo=this.IdPersonInfo
    ? {
      ...this.laboralForm.value,
      idPersona:this.IdPersonInfo
    }
    : this.laboralForm.value;

    this.personInfoService.save(this.IdPersonInfo,model)
    .subscribe(
      data=>{
        console.log(data);
      },
      error=>{
        console.log(error);
      }
    );
  }

  OnSaverelPersonNumEmpleado(){
    let model:RelPersonaNumempleado={
      ...this.personalInfo.value,
      idPersona:this.IdPerson
    };

    console.log(this.NumEmpleado);
    this.NumEmpleado==undefined ?
    this.relPersonNumempleadoService.post(model)
    .subscribe(
      data=>{
        console.log(data);
        this.NumEmpleado=data.numEmpleado;
      },
      error=>{console.log(error);}
    )
    :
    this.relPersonNumempleadoService.put(model)
    .subscribe(
      data=>{
        console.log(data);
      },
      error=>{console.log(error);}
    );
  }

  retrieveCatalogs(){
    this.catalogs.getNacionalidades().subscribe(
      data => { this.nacionalidadItems = data; },
      error => { console.log(error); }
    );

    this.catalogs.getGenders().subscribe(
      data => { this.generoItems = data; },
      error => { console.log(error); }
    );

    this.catalogs.getEdosCiviles().subscribe(
      data => { this.estadoCivilItems = data;},
      error => { console.log(error);}
    );

    this.catalogs.getReligiones().subscribe(
      data => { this.religionItems = data; },
      error => { console.log(error); }
    );

    this.catalogs.getEstados().subscribe(
      data => { this.edosItems = data; },
      error => { console.log(error); }
    );

    //MUNICIPIO
    //COLONIA

    this.catalogs.getNivelesEducativos().subscribe(
      data => { this.nivelEducativoItems = data; },
      error=> { console.log(error); }
    );

    this.catalogs.getPhoneTypes().subscribe(
      (data: any) => { this.phoneTypes = data; },
      error => console.log('getPhoneTypes.error', error)
    );

    this.catalogs.getMailTypes().subscribe(
      (data: any) => { this.mailTypes = data; },
      error => console.log('getMailTypes.error', error)
    );
  }

  retrieveCatalogsByClient(){
    this.departmentService.getAll(this.IdClient)
    .subscribe(
      (data: any) => {
        this.departmentItems = data;
      },
      error=>{
        console.log(error);
      }
    );

    this.clientService.get(this.IdClient)
    .subscribe(
      data=>{
        this.listClients.push(data);
        this.personalInfo.controls["idClient"].setValue(this.IdClient);
      },
      error=>{
        console.log(error);
      }
    );
  }

  retrieveSepomexCatalog(codigo:string){
    let model:SepomexInterface=this.addressForm.value;
    this.sepomexService.getByCP(model["CP"])
    .subscribe(
      data=>{
        this.sepomexItems=data;
        if(codigo!==undefined)
        {
          this.addressForm.controls["codSepomex"].setValue(codigo);
        }
      },
      error=>{
        console.log(error);
      }
    );
  }

  retrievePersonTest(){
    let user=JSON.parse(sessionStorage.getItem("X-SYMASUSER"));
    this._relPersonTest.get(null,user.idUser)
    .subscribe(
      data =>{
        console.log('_relPersonTest', data);
        if(data["status"]=="I"){ 
          this.utilsService.showMessage("Cuestionario No Disponible","Info");
          this.authenticationService.logout();
        }

        if(data["status"]=="T"){ 
          this.authenticationService.redirect('/userQuestDashboard');
        }

        this.onDisabled(data["status"]);
        data["person"]["listClientPersons"].forEach(element => {
          this.IdFilial=element["idFilial"]
        });

        this.affiliateCustomerService.get(this.IdFilial)
        .subscribe(
          data=>{
            this.IdClient=data["idCliente"];
            this.listAffiliateCustomer.push(data);
            this.personalInfo.controls["idFilial"].setValue(this.IdFilial);
            this.retrieveCatalogsByClient();
          },
          error=>{
            console.log(error);
          }
        );

        this.IdPerson=data["person"]["id"];
        this.getPhones();
        this.getMails();
        this.personalInfo.controls["name"].setValue(data["person"]["name"]);
        this.personalInfo.controls["paternal"].setValue(data["person"]["paternal"]);
        this.personalInfo.controls["maternal"].setValue(data["person"]["maternal"]);

        this.personalInfo.controls["dateBirth"].setValue(data["person"]["dateBirth"]);
        this.personalInfo.controls["stateId"].setValue(data["person"]["stateId"]);
        this.personalInfo.controls["nacionalityId"].setValue(data["person"]["nacionalityId"]);

        this.personalInfo.controls["genderId"].setValue(data["person"]["genderId"]);
        this.IdGender=Number(data["person"]["genderId"]);
        this.personalInfo.controls["maritalStatusId"].setValue(data["person"]["maritalStatusId"]);
        this.personalInfo.controls["religionId"].setValue(data["person"]["religionId"]);

        this.personalInfo.controls["CURP"].setValue(data["person"]["curp"]);

        if(data["person"]["relPersonNumEmpleado"]!=null)
        {
          this.NumEmpleado=data["person"]["relPersonNumEmpleado"]["numEmpleado"];
          this.personalInfo.controls["numEmpleado"].setValue(data["person"]["relPersonNumEmpleado"]["numEmpleado"]);
        }

        this.calculaEdad();
        this.IdForm=data["cueVersionCuestionario"]["cuestionario"]["formId"];
        this.IdCuestionario=data["cueVersionCuestionario"]["id"];
        this.retrieveForm(data["cueCuestionarioRespuestas"]!=null ? data["cueCuestionarioRespuestas"]["respuestas"] : null);

        this.IdPersonInfo=data["person"]["personInfo"]["idPersona"];
        this.laboralForm.controls["numHijos"].setValue(data["person"]["personInfo"]["numHijos"]);
        this.laboralForm.controls["idNivelEducativo"].setValue(data["person"]["personInfo"]["idNivelEducativo"]);

        this.laboralForm.controls["idDepartamento"].setValue(data["person"]["personInfo"]["idDepartamento"]);
        
        if(data["person"]["personInfo"]["idDepartamento"]!=null){
          this.retrievePuesto(data["person"]["personInfo"]["idDepartamento"]);
          this.laboralForm.controls["idPuesto"].setValue(data["person"]["personInfo"]["idPuesto"]);
        }

        
        this.laboralForm.controls["antTrabajoIni"].setValue(data["person"]["personInfo"]["antTrabajoIni"]);
        this.laboralForm.controls["antTrabajoFin"].setValue(data["person"]["personInfo"]["antTrabajoFin"]);
        this.laboralForm.controls["antPuestoIni"].setValue(data["person"]["personInfo"]["antPuestoIni"]);
        this.laboralForm.controls["antPuestoFin"].setValue(data["person"]["personInfo"]["antPuestoFin"]);

        data["person"]["listPacientAddress"].forEach(element => {
          this.IdAddress=element["address"]["id"];
          this.addressForm.controls["calle"].setValue(element["address"]["calle"]);
          this.addressForm.controls["numExterior"].setValue(element["address"]["numExterior"]);
          this.addressForm.controls["numInterior"].setValue(element["address"]["numInterior"]);
          this.addressForm.controls["entreCalle1"].setValue(element["address"]["entreCalle1"]);
          this.addressForm.controls["entreCalle2"].setValue(element["address"]["entreCalle2"]);

          this.addressForm.controls["CP"].setValue(element["address"]["sepomex"]["d_codigo"]);
          this.retrieveSepomexCatalog(element["address"]["sepomex"]["codigo"]);
          this.addressForm.controls["d_estado"].setValue(element["address"]["sepomex"]["d_estado"]);
          this.addressForm.controls["d_mnpio"].setValue(element["address"]["sepomex"]["d_mnpio"]);
          this.addressForm.controls["latitud"].setValue(element["address"]["latitud"]);
          this.addressForm.controls["longitud"].setValue(element["address"]["longitud"]);
        });
      },
      error =>{
        console.log(error);
      }
    );
  }

  getLocation(){
    var geo_options = {
      enableHighAccuracy: true,
      maximumAge        : 0,
      timeout           : 5000
    };

    navigator.geolocation.getCurrentPosition((pos)=>{
      console.log(pos);
      let crd=pos.coords;

      this.addressForm.controls["latitud"].setValue(crd.latitude);
      this.addressForm.controls["longitud"].setValue(crd.longitude+(-0.0049019));
    },()=>{
      console.log("error");
    }, geo_options)
  }

  retrieveForm(respuestas:any){
    let formGroup: any = {};
    this.Respuestas=respuestas;

    this.formService
    .get(this.IdForm)
    .subscribe(
      data => {
        this.tabs=data.tabs;
          this.tabs.forEach(tab=>{
            formGroup[`menuTab${tab.id}`] = new FormControl(false);
            this.arrayClass.push("tablinks gradient-button gradient-button-3");

            tab.secctions.forEach(sec => {
              sec.items.forEach(item => {
                formGroup[item.name] = new FormControl(item.defaultValue);
              });
            });
          });
          this.form = new FormGroup(formGroup);

          if(respuestas!=null && respuestas.length>0){
            this.array=[true];
            this.arrayClass=[""];

            respuestas.forEach(element => {
              if(element["columnName"]!="Status"){
                if(element["columnName"].includes("menuTab"))
                {
                  if(element["respuesta"]==='true')
                  {
                     this.form.controls[element["columnName"]].setValue(true);
                     this.array.push(true);
                     this.arrayClass.push("tablinks gradient-button gradient-button-3 inactive");
                  }
                  else{
                    this.array.push(false);
                    this.arrayClass.push("tablinks gradient-button gradient-button-3");
                  }
                }
                else{
                  if(element["respuesta"]!=''){
                    this.form.controls[element["columnName"]].setValue(element["respuesta"]);
                  }
                }
              }
            });
          }
          
          setTimeout(()=>{
            let model:Person={
              ...this.personalInfo.value
            };
            this.onGenderDisplay(model.genderId);
          }, 2000);
      },
      error => { console.log(error);}
    );
  }

  calculaEdad=()=>{
    let model={...this.personalInfo.value};
    let date;
    var birthday_arr;
    var birthday_date;

    if(model["dateBirth"]["_i"]==undefined){
      date=model["dateBirth"].toString().split("T");
      //console.log("If",date[0]);
      birthday_arr = date[0].split("-");
      birthday_date = new Date(birthday_arr[0], birthday_arr[1] - 1, birthday_arr[2]);
    }else{
      date=model["dateBirth"]["_i"];
      //console.log("Else",date);
      birthday_arr = date.split("/");
      birthday_date = new Date(birthday_arr[2], birthday_arr[1] - 1, birthday_arr[0]);
    }
    
    var ageDifMs = Date.now() - birthday_date.getTime();
    var ageDate = new Date(ageDifMs);

    this.Edad=Math.abs(ageDate.getUTCFullYear() - 1970);
    this.onGenderDisplay(model["genderId"]);
    //console.log(model["genderId"]);
    //console.log("Edad",this.Edad);
  }

  onChangeGender = (event:Event):void =>{
    let idGender=(event.target as HTMLInputElement).value;
    
    this.onGenderDisplay(idGender);
  }

  onGenderDisplay(idGender:any){
    var divRadGender = document.getElementsByClassName("ocultarGender");

    for (var i = 0; i < divRadGender.length; i++) {
      document.getElementById(`${divRadGender[i].id}`).className = "ocultar ocultarGender";
      //console.log(divRadGender[i].id);
    }

    for (var i = 0; i < divRadGender.length; i++) {
      if(divRadGender[i].id.includes(`Gender${idGender}`)){
        
        document.getElementById(`${divRadGender[i].id}`).className = "visualizar ocultarGender";

        if(idGender==3 && this.Edad < 40 && divRadGender[i].id.includes(`divRad_item173_Gender3`)){
          document.getElementById(`${divRadGender[i].id}`).className = "ocultar ocultarGender";
        }
      } 
    }
  }

  onChangeDepartment = (event: Event): void => {
    const departmentId = (event.target as HTMLInputElement).value;

    this.retrievePuesto(departmentId);    
  }

  retrievePuesto(departmentId:string){
    this.puestoService.getAll(departmentId)
        .subscribe(
          (data: any) => {
            this.puestoItems = data;
          },
          error => {
            console.log(error);
          }
    );
  }

  OnChangeSepomex(event:Event)
  {
    this.sepomexService.getByKey((event.target as HTMLInputElement).value)
    .subscribe(
      data=>{
        if(data==null)
        {
          this.addressForm.controls["d_estado"].setValue("NO SELECCIONADO");
          this.addressForm.controls["d_mnpio"].setValue("NO SELECCIONADO");
        }
        else{
          this.addressForm.controls["d_estado"].setValue(data.d_estado);
          this.addressForm.controls["d_mnpio"].setValue(data.d_mnpio);
        }

      },
      error=>{
        console.log(error);
      }
    );
  }

  OnCalculateCurp()
  {
    let model:Person=this.personalInfo.value;
    
    if(!this.personalInfo.valid)
    {
      this.utilsService.showMessage("Son Necesarios Varios Datos Para Calcular","Error");
      return;
    }

    this.calculateCurpService.getCurp({
      name: model.name,
      paternal: model.paternal,
      maternal: model.maternal,
      gender: model.genderId == 2 ? 'M' : 'H',
      birthDate: model.dateBirth,
      placeOfBirth: model.stateId.toString()
    })
    .subscribe(
      data => {
        this.personalInfo.controls["CURP"].setValue(data["rfc"]);
      },
      error => {
        console.log(error);
      }
    );
  }

  OnChange(event:Event){
    let childs=(event.target as HTMLInputElement).childNodes;

    childs.forEach(element => {
      if((element as HTMLOptionElement).value!==undefined)
      {
        document.getElementById(`divOpc_${(element as HTMLOptionElement).value}`).className = "ocultar";
      }
    });

    document.getElementById(`divOpc_${(event.target as HTMLInputElement).value}`).className = "visualizar";
  }

  OnChangeRadio(event:Event,item:any){
    item["values"].forEach(element => {
      document.getElementById(`divRad_${item["name"]}_${element["value"]}`).className = "ocultar";
    });

    document.getElementById(`divRad_${item["name"]}_${event["value"]}`).className = "visualizar";
  }

  openTab(evt, tabName){
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  openMapsDialog(){
    const dialogRef = this.dialog.open(UserQuestionnareDialog,{
      width: '500px',
      data:{
        latitud:this.addressForm.controls["latitud"].value,
        longitud:this.addressForm.controls["longitud"].value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!==undefined){
        this.addressForm.controls["latitud"].setValue(result["latitud"]);
        this.addressForm.controls["longitud"].setValue(result["longitud"]);
      }
    });
  }

  getPhones = (): void => {
    this.phoneServices.getAll(this.IdPerson)
      .subscribe(
        (data: any) => {
          this.phones = data;
        },
        error => console.log("get Phones error", error)
      );
  };

  getMails = (): void => {
    this.mailServices.getAll(this.IdPerson)
      .subscribe(
        (data: any) => {
          this.mails = data;
        },
        error => console.log("get Mails error", error)
      );
  };

  onSavePhone = (): void => {
    if(this.phoneForm.valid) {
      const model = {
        ...this.phoneForm.value,
        peoplePhones: [{ peopleId: this.IdPerson }],
      };

      this.phoneServices.save(model)
        .subscribe(
          () => this.getPhones(),
          error => console.log("Save Phone error", error)
        );
    }
  };

  onDeletePhone = (item: Phone): void => {
    this.phoneServices.delete(item.id)
      .subscribe(
        () => this.getPhones(),
        error => console.log("Delete Phone error", error)
      );
  };

  onSaveMail = (): void => {
    if(this.mailForm.valid) {
      const model = {
        ...this.mailForm.value,
        peopleMails: [{ peopleId: this.IdPerson }],
      };

      this.mailServices.save(model)
        .subscribe(
          () => this.getMails(),
          error => console.log("Save Mail error", error)
        );
    }
  };

  onDeleteMail = (item: Mail): void => {
    this.mailServices.delete(item.id)
      .subscribe(
        () => this.getMails(),
        error => console.log("Delete Mail error", error)
      );
  };

  onDisabled(status:string){
    if(status=="T")
    {
      this.disabledItems=true;
    }
  }

  onMenuDisabled(tabId:any){
    document.getElementById(`menuTTab${tabId}`).className += " inactive";
  }

  private initPhoneForm = (): FormGroup =>
    this.fb.group({
      phoneNumber: ['', [Validators.required]],
      extensionNumber: ['', []],
      phoneTypesId: [0, [Validators.required]]
    });

  private initMailForm = (): FormGroup =>
    this.fb.group({
      email: ['', [Validators.required]],
      mailTypesId: [0, [Validators.required]]
    });
}

@Component({
  selector: 'userQuestionnaireDialog',
  templateUrl: 'userQuestionnaireDialog.html',
  styleUrls:["userQuestionnaireDialog.css"]
})
export class UserQuestionnareDialog implements OnInit {
  ubicacionCentral:Coordenada;
  ubicacionSeleccionada:Coordenada;
  coordenadas : Coordenada[] = [];

  constructor(public dialogRef: MatDialogRef<UserQuestionnareDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(){
    if(this.data["latitud"]!=null && this.data["longitud"]!=null){
      this.coordenadas=[];
      this.ubicacionCentral = new Coordenada(this.data["latitud"],this.data["longitud"]);
      let coord = new Coordenada(this.data["latitud"], this.data["longitud"]);
      this.coordenadas.push(coord);
      this.ubicacionSeleccionada=coord;
    }else{
      this.ubicacionCentral = new Coordenada(19.4326077,-99.133208);
    }
  }

  mapClicked($event: MouseEvent) {
    this.coordenadas=[];
    let coord = new Coordenada($event.coords.lat, $event.coords.lng);
    this.coordenadas.push(coord);
    this.ubicacionSeleccionada=coord;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
