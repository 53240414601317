import { Component, OnInit } from '@angular/core';
import { Coordenada } from '../models/coordenada';
import { LayoutService } from '../services/layouts/layouts.service';
import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls:['./home.component.css']
})
export class HomeComponent implements OnInit {
  ubicacionCentral:Coordenada;
  coordenadas : Coordenada[] = [];
  
  constructor(public layoutService:LayoutService){
    this.layoutService.title="Home"
  }

  ngOnInit(){
    this.ubicacionCentral = new Coordenada(19.4326077,-99.133208);
  }

  mapClicked($event: MouseEvent) {
    let coord = new Coordenada($event.coords.lat, $event.coords.lng);
    this.coordenadas.push(coord);
  }
}
