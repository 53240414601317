import { Component, Input } from "@angular/core";

@Component({
    selector:"app-loader2",
    templateUrl:"./loader2.component.html",
    styleUrls:["./loader2.component.css"]
})

export class Loader2Component{
    @Input() isShowing:boolean;

    constructor(){}
}