import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Job } from "src/app/models";

const { baseUrl } = environment;

@Injectable({
  providedIn:'root'
})

export class PuestoService{
  constructor(private http:HttpClient){}

  getAll = (parameters: string) :Observable<Job> => this.http.get<Job>(`${baseUrl}/Jobs?DepartmentId=${parameters}`);

  get = (id: string): Observable<Job> => this.http.get<Job>(`${baseUrl}/Jobs/${id}`);

  save = (data: Job): Observable<Job> => data.id
    ? this.http.put<Job>(`${baseUrl}/Jobs/${data.id}`, data)
    : this.http.post<Job>(`${baseUrl}/Jobs`, data);

  delete = (id: string): Observable<Job> => this.http.delete<Job>(`${baseUrl}/Jobs/${id}`);
}
