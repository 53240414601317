import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const { baseUrl } = environment;

@Injectable({
  providedIn:'root'
})

export class relPersonTestService{
    constructor(private http:HttpClient){}

    getAll(idTest): Observable<any> {
      return this.http.get(`${baseUrl}/RelPersonTest/${idTest}`);
    }

    get(personId,userId):Observable<any>{
      let parameters="";
      if(personId!=null && userId!=null){
        parameters=`personId${personId}&userId=${userId}`
      }
      else if(personId!=null){
        parameters=`personId=${personId}`
      }
      else{
        parameters=`userId=${userId}`
      }
      return this.http.get(`${baseUrl}/RelPersonTest?${parameters}`);
    }

    create(data): Observable<any> {
      return this.http.post(`${baseUrl}/RelPersonTest`, data);
    }

    update(id, data): Observable<any> {
      return this.http.put(`${baseUrl}/RelPersonTest/${id}`, data);
    }

    importUsers = (data: any): Observable<any> => this.http.post<any>(`${baseUrl}/RelPersonTest/importUsers`, data);

    delete(id): Observable<any> {
      return this.http.delete(`${baseUrl}/RelPersonTest/${id}`);
    }
}
