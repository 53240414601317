import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Person, CurpViewModel } from "src/app/models";
import { environment } from "src/environments/environment";

const {baseUrl} = environment;

@Injectable({
  providedIn:'root'
})

export class CalculateCURPService
{
  constructor(private http: HttpClient){}

  getCurp = (data: CurpViewModel ): Observable<Person> => this.http.post<Person>(`${baseUrl}/peoples/calculaCurp`, data);
}
