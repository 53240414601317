import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn:'root'
})
export class AutenticationService{
  email:string="";
  userName:string="";
  idUser:string="";
  menuItems: Object[] = [];

  constructor(private router:Router){
    this.userValid();
  }

  public userValid(){
    let usuarioActual=JSON.parse(sessionStorage.getItem('X-SYMASUSER'));
    let idRol:string;
    
    if(usuarioActual!=null)
    {
      this.email=usuarioActual["email"];
      this.idUser=usuarioActual["idUser"];
      usuarioActual["listUserRoles"].forEach(function(value) {
        switch (value['idRol'].toUpperCase()) {
          case "E4DB2AAC-7BDF-47B2-A212-5F823A068F3B":
            idRol=value['idRol'];
            break;
          default:
            idRol=value['idRol']
            break;
        }
      });
      this.loadMenu(idRol);
    }
    else{
      this.router.navigate(['/']);
    }
  }

  public loadMenu(idRol:string){
    let menuList: Object[] = [];
      switch (idRol.toUpperCase()) {
        case "E4DB2AAC-7BDF-47B2-A212-5F823A068F3B":
        break;
        default:
          menuList.push({ classes: 'navLink', routerLink: ['/questionnaires/settings'], text: 'Configuracion de Cuestionarios' });
          menuList.push({ classes: 'navLink', routerLink: ['/questionnaires'], text: 'Cuestionarios' });
          menuList.push({ classes: 'navLink', routerLink: ['/providers'], text: 'Proveedores' });
          menuList.push({ classes: 'navLink', routerLink: ['/mailConfigurationCompanies'], text: 'Configuración de Correos' });
          menuList.push({ classes: 'navLink', routerLink: ['/forms'], text: 'Formularios' });
        break;
      }
    this.menuItems=menuList;
  }

  public logout(): void {
    sessionStorage.removeItem("X-SYMASHEADER");
    sessionStorage.removeItem("X-SYMASUSER");
    this.router.navigate(['/']);
  }

  public redirect(urlStr:string) {
    this.router.navigate([`${urlStr}`]);
  }
}
