import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddressInterface } from "src/app/models/address";
import { environment } from "src/environments/environment";

const {baseUrl}=environment;

@Injectable({
  providedIn:'root'
})

export class AddressService{
  constructor(private http:HttpClient){}

  getAll = () : Observable<AddressInterface> => this.http.get<AddressInterface>(`${baseUrl}/Address`);

  get = (id: string): Observable<AddressInterface> => this.http.get<AddressInterface>(`${baseUrl}/Address/${id}`);

  save = (person:string,data: AddressInterface): Observable<AddressInterface> => data.id
    ? this.http.post<AddressInterface>(`${baseUrl}/Address/${data.id}`, data)
    : this.http.post<AddressInterface>(`${baseUrl}/Address?person=${person}`, data);

  delete = (id: string): Observable<AddressInterface> => this.http.delete<AddressInterface>(`${baseUrl}/Address/${id}`);
}