import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const {baseUrl}=environment;

@Injectable({
  providedIn:"root"
})

export class CueCuestionarioRespuestasService{
  constructor(private http:HttpClient){}

  post(data):Observable<any>{
    return this.http.post(`${baseUrl}/CueCuestionarioRespuestas`, data);
  }
}