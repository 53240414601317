import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Form } from "src/app/models/Form.Interface";
import { FormsService } from "src/app/services/forms/forms.service";
import { LayoutService } from "src/app/services/layouts/layouts.service";
import { UtilsService } from "src/app/services/utils/utils.service";

@Component({
  selector:'app-newForm',
  templateUrl:'./newForm.component.html',
  styleUrls:['./newForm.component.css']
})

export class NewFormComponent implements OnInit{
  formId:any;

  formulario=new FormGroup({
    name: new FormControl('',Validators.required),
    description:new FormControl(''),
    dateAdd:new FormControl(new Date()),
    dataTable:new FormControl(""),
    status:new FormControl(true),
  });

  constructor(
    public layoutService:LayoutService,
    public utilsService:UtilsService,
    private formService:FormsService,
    private router:Router,
    private route:ActivatedRoute
  ){
    layoutService.title="Form - Add / Edit ";
  }

  ngOnInit(){
    this.formId=this.route.snapshot.paramMap.get('id');
    if(this.formId!=='new'){
      this.retrieveForm();
    }
  }

  retrieveForm(){
    this.formService.get(this.formId)
    .subscribe(
      data => {
        this.formulario.controls['name'].setValue(data.name);
        this.formulario.controls['description'].setValue(data.description);
        this.formulario.controls['dateAdd'].setValue(data.dateAdd);
        this.formulario.controls['dataTable'].setValue(data.dataTable);
        this.formulario.controls['status'].setValue(data.status);
      },
      error=>{
        console.log(error);
      });
  }

  OnSave(){
    let model:Form=this.formulario.value;

    if(this.formId==='new')
    {
      console.log("model",model);
      this.formService.create(model)
      .subscribe(
        data=>{
          this.formId=data.id;
          this.utilsService.showMessage("Formulario Guardado","Correcto");
          this.router.navigate([`/newForm/${data.id}`]);
        },
        error=>{
          console.log(error);
        }
      );
    }
    else{
      this.formService.update(this.formId,model)
      .subscribe(
        data=>{
          this.utilsService.showMessage("Formulario Actualizado","Correcto");
        },
        error=>{
          console.log(error);
        }
      );
    }
  }
}
