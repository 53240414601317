import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";

@Component({
  selector: 'app-import-user-modal-form',
  templateUrl: './import-user-modal-form.component.html',
  styleUrls: ['./import-user-modal-form.component.css']
})
export class ImportUserModalFormComponent implements OnInit {
  // importUsersForm: FormGroup;
  file: any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ImportUserModalFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.importUsersForm = this.initImportUsersForm();
  }

  ngOnInit() {
  }

  // private initImportUsersForm = (): FormGroup =>
  //   this.fb.group({
  //     file: ['', [Validators.required]]
  //   });

  onSelectedFile = (event: any): void => {
    this.file = (event.target.files.length > 0)
      ? event.target.files[0]
      : null;
  }

  onNoClick = (): void => this.dialogRef.close();
}
