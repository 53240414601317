import { AfterViewInit, Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MatSnackBar,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition, MAT_DIALOG_DATA ,MatDialog} from "@angular/material";
import { CatalogInterface } from "src/app/models/catalog";
import { FormSectionItemInterface } from "src/app/models/formSectionItem";
import { FormSectionItemValueInterface } from "src/app/models/formSectionItemValue";
import { ItemValueOptionInterface } from "src/app/models/itemValueOption";
import { CatalogsService } from "src/app/services/catalogs/catalogs.service";
import { FormSectionItemsService } from "src/app/services/forms/formSectionItem.service";
import { FormSectionItemValuesService } from "src/app/services/forms/formSectionItemValue";
import { ItemValueOptionsService } from "src/app/services/forms/itemValueOption.service";
import { UtilsService } from "src/app/services/utils/utils.service";

@Component({
  selector:'app-formSectionItem',
  templateUrl:'./formSectionItem.component.html',
  styleUrls:['./formSectionItem.component.css']
})

export class FormSectionItemComponent implements OnInit{
  @Input() formSectionId=0;
  idItem:any='new';
  formsSectionsItems:FormSectionItemInterface[]=[];
  fomrsSectionsItemsValues:FormSectionItemValueInterface[]=[];
  typeItems:CatalogInterface[]=[];

  formSectionItem:any;

  formSectionItemValue=new FormGroup({
    option: new FormControl('',Validators.required),
    value:new FormControl(''),
  });

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private formSectionItemsService:FormSectionItemsService,
    private formSectionItemValuesService:FormSectionItemValuesService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ){}

  ngOnInit(){
    this.retrieveFormSectionItems();
  }

  retrieveFormSectionItems(){
    this.formSectionItemsService.getAll(this.formSectionId)
    .subscribe(
      data=>{
        this.formsSectionsItems=data;
      },
      error=>{
        console.log(error);
      }
    );
  }

  OnSave(){
    let model:FormSectionItemInterface=this.formSectionItem.value;
    model['idSection']=this.formSectionId;
    model['values']=this.fomrsSectionsItemsValues;

    if(this.idItem=='new'){
      this.formSectionItemsService.create(model)
        .subscribe(
          data=>{
            console.log(data);
            this.retrieveFormSectionItems();
            this.ShowMessage('succes');
          },
          error=>{
            console.log(error);
            this.ShowMessage('error');
          }
      );
    }
    else{
      this.formSectionItemsService.update(this.idItem,model)
      .subscribe(
          data=>{
            console.log(data);

            this.retrieveFormSectionItems();
            this.ShowMessage('succes');
          },
          error=>{
            console.log(error);
            this.ShowMessage('error');
          }
      );
    }
    
  }

  OnAddValue(){
    let model=this.formSectionItemValue.value;
    this.fomrsSectionsItemsValues.push(model);
  }

  OnRemoveValue(row){
    for (var i = this.fomrsSectionsItemsValues.length - 1; i >= 0; --i) 
    {
      if (this.fomrsSectionsItemsValues[i].option == row.option) {
        this.fomrsSectionsItemsValues.splice(i,1);
      }
    }
  }



  OnEdit(item){
    console.log(item);return;
    this.idItem=item.id;
    this.formSectionItem.controls['name'].setValue(item.name);
    this.formSectionItem.controls['label'].setValue(item.label);
    this.formSectionItem.controls['idTypeItem'].setValue(item.idTypeItem);
    this.formSectionItem.controls['status'].setValue(item.status);
    this.fomrsSectionsItemsValues=item.values;
  }

  OnRemoveItem(item){
    //console.log("remove",item);
    this.OnRemoveItemValues(item.values);
    this.formSectionItemsService.delete(item.id)
        .subscribe(
          data=>{
            this.retrieveFormSectionItems();
            this.ShowMessage('succes');
          },
          error=>{
            this.ShowMessage('error');
          }
      );
  }

  OnSaveItemValue(idItem,element){
    let model:FormSectionItemValueInterface={
      id:0,
      idItem:idItem,
      option:element.option,
      value:element.value,
      status:true
    };

    this.formSectionItemValuesService.create(model)
      .subscribe(
        data=>{
          console.log(data);
        },
        error=>{
          console.log(error);
        }
      );
  }

  OnRemoveItemValues(values){
    values.forEach(element => {
      this.formSectionItemValuesService.delete(element.id)
      .subscribe(
        data=>{
          console.log(data);
        },
        error=>{
          console.log(error);
        }
      );
    });
  }

  ShowMessage(message){
      this._snackBar.open(message, '', {
      duration: 3 * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openDialog(item:any): void {
    const dialogRef = this.dialog.open(FormSectionItemDialog, {
      width: '1200px',
      height:'700px',
      data: {
        idFormSection:this.formSectionId,
        item:item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!==undefined){
        console.log(result);
        this.formSectionItem=result.form;
        this.fomrsSectionsItemsValues=result.formItemsValues;
        this.OnSave();
      }

      this.retrieveFormSectionItems();
    });
  }
}

export interface tableItemModel {
  typeItem: string;
  label: string;
}


@Component({
  selector: 'formSectionItemDialog',
  templateUrl: 'formSectionItemDialog.html',
  styles:[".ocultar {display: none;} .visualizar {display: display;}"]
})
export class FormSectionItemDialog implements OnInit,AfterViewInit {
  dataItem:any;
  formSectionId=0;
  dataSource:tableItemModel[]=[];
  displayedColumns: string[] = ['typeItem', 'label'];
  typeItems:CatalogInterface[]=[];
  listItems:FormSectionItemInterface[]=[];
  listItemValues:FormSectionItemValueInterface[]=[];

  fomrsSectionsItemsValues:FormSectionItemValueInterface[]=[];

  formSectionItem=new FormGroup({
    name: new FormControl('',Validators.required),
    label:new FormControl(''),
    idTypeItem:new FormControl(1),
    status:new FormControl(true),
  });

  formOptionItemValue=new FormGroup({
    idItem:new FormControl(),
    idOptionValue:new FormControl()
  });

  formSectionItemValue=new FormGroup({
    option: new FormControl('',Validators.required),
    value:new FormControl(''),
  });

  constructor(
    public utilsService:UtilsService,
    private catalogsService:CatalogsService,
    private formSectionItemsService:FormSectionItemsService,
    private formSectionItemValuesService:FormSectionItemValuesService,
    public dialogRef: MatDialogRef<FormSectionItemDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) 
  {
    this.formSectionId=data.idFormSection;
    
    if(data.item!==undefined)
    {
      this.dataItem=data.item;
      this.formSectionItem.controls['name'].setValue(data.item.name);
      this.formSectionItem.controls['label'].setValue(data.item.label);
      this.formSectionItem.controls['idTypeItem'].setValue(data.item.idTypeItem);
      this.fomrsSectionsItemsValues=data.item.values;
    }
  }

  ngOnInit(){
    this.retrieveTypeItem();
  }

  ngAfterViewInit(){
    if(this.dataItem!==undefined)
    {
      this.ShowValues(this.dataItem.idTypeItem.toString());
    }
    this.retrieveItems();
  }

  retrieveItems(){
    this.formSectionItemsService.getAll(this.formSectionId)
    .subscribe(
      data=>{
        this.listItems=data;
        //this.retrieveItemValues(data[0]["id"]);
      },
      error=>{
        console.log(error);
      }
    );
  }

  retrieveItemValues(idItem:number){
    this.formSectionItemValuesService.getAll(idItem)
    .subscribe(
      data=>{
        if(data.length!=0)
        {
          this.listItemValues=data;
          //this.retrieveItemsByParent(data[0]["id"]);
        }
        else{
          this.listItemValues=[];
          this.dataSource=[];
        }
      },
      error=>{
        console.log(error);
      }
    );
  }

  retrieveItemsByParent(idParent:number){
    this.formSectionItemsService.getItemsByParent(idParent)
    .subscribe(
      data=>{
        let _tableElements:tableItemModel[]=[];
        data.forEach(element => {

          let _element:tableItemModel={
            typeItem:this.getTipoItem(Number(element["idTypeItem"])),
            label:element["label"]
          };

          _tableElements.push(_element);
        });
        
        this.dataSource=_tableElements;
      },
      error=>{
        console.log(error);
      }
    );
  }

  retrieveTypeItem():void{
    this.catalogsService.getFormularioSectionItem()
    .subscribe(data => {
      this.typeItems=data;
      },
      error=>{
        console.log(error);
      });
  }

  OnSave(){
    let model:FormSectionItemInterface=this.dataItem!==undefined
    ? {
      id:this.dataItem.id,
      ...this.formSectionItem.value
    }
    : this.formSectionItem.value; 
    
    model['idSection']=this.formSectionId;
    model['values']=this.fomrsSectionsItemsValues;
    
    if(this.dataItem===undefined)
    {
      this.formSectionItemsService.create(model)
      .subscribe(
        data=>{
          this.formSectionItem.controls["name"].setValue("");
          this.formSectionItem.controls["label"].setValue("");
          this.formSectionItem.controls["idTypeItem"].setValue("1");
          this.fomrsSectionsItemsValues=[];
          this.ShowValues("1");
          this.utilsService.showMessage("Item Guardado","Correcto");
          this.retrieveItems();
        },
        error=>{
          this.utilsService.showMessage("Error al guardar el item","Error");
          console.log(error);
        }
      );
    }
    else{
      this.formSectionItemsService.update(this.dataItem["id"],model)
      .subscribe(
        data=>{
          this.utilsService.showMessage("Item Actualizado","Correcto");
        },
        error=>{
          this.utilsService.showMessage("Error al actualizar el item","Error");
          console.log(error);
        }
      );
    }
  }

  OnChange(event:Event){
    const valor=(event.target as HTMLInputElement).value;
    this.fomrsSectionsItemsValues=[];
    this.ShowValues(valor);
  }

  OnChangeItem(evt:Event){
    this.retrieveItemValues(Number((evt.target as HTMLInputElement).value));
  }

  OnChangeOption(evt:Event){
    this.retrieveItemsByParent(Number((evt.target as HTMLInputElement).value));
  }

  OnAddValue(){
    let model=this.formSectionItemValue.value;
    model['status']=true;
    this.fomrsSectionsItemsValues.push(model);
  }

  OnRemoveValue(row){
    for (var i = this.fomrsSectionsItemsValues.length - 1; i >= 0; --i) 
    {
      if (this.fomrsSectionsItemsValues[i].option == row.option) {
        this.fomrsSectionsItemsValues.splice(i,1);
      }
    }
  }


  ShowValues(valor){
    switch(valor){
      case "2":{
        document.getElementById(`divTblValues_${this.formSectionId}`).className = "visualizar";
        break;
      }
      case "3":{
        document.getElementById(`divTblValues_${this.formSectionId}`).className = "visualizar";
        break;
      }
      case "4":{
        document.getElementById(`divTblValues_${this.formSectionId}`).className = "visualizar";
        break;
      }
      default:{
        document.getElementById(`divTblValues_${this.formSectionId}`).className = "ocultar";
      }
    } 
  }

  abrirDialog(value:any): void {
    let model=this.formOptionItemValue.value;
    
    const dialogRef = this.dialog.open(ItemValueOptionDialog, {
      height:'380px',
      data: {
        valueItem:model["idOptionValue"],
        idSecction:this.formSectionId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!==undefined){
        let _model:FormSectionItemInterface=result["form"];
        _model.parentItemValue=model["idOptionValue"];
        _model.idSection=result["idSecction"];
        _model.values=result["formItemsValues"];
        this.OnSaveItemWithParent(_model);
      }
    });
  }

  OnSaveItemWithParent(model:FormSectionItemInterface){
    this.formSectionItemsService.create(model)
    .subscribe(
      data=>{
        this.utilsService.showMessage("Item Guardado","Correcto");
        this.retrieveItemsByParent(Number(model["parentItemValue"]));
      },
      error=>{
        this.utilsService.showMessage("Error al guardar el item","Error");
        console.log(error);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTipoItem(idTypeItem:number)
  {
    switch(idTypeItem){
      case 1: { 
        return "Text";
      } 
      case 2: { 
          return "CheckBox";
      }
      case 3: { 
        return "RadioButton";
      }
      case 4: { 
        return "DropDownList";
      }
      case 5: { 
        return "DatePicker";
      }
      case 6: {
        return "Number";
      }
     default: { 
        return "Text" 
     } 
    }
  }
}

@Component({
  selector: 'ItemValueOptionDialog',
  templateUrl: 'itemValueOptionDialog.html',
  styles:[".ocultar {display: none;} .visualizar {display: display;}"]
})
export class ItemValueOptionDialog implements OnInit{
  idSecction:any=0;
  dataValueItem:FormSectionItemValueInterface;
  optiontypeItems:CatalogInterface[]=[];
  itemsValuesOptions:ItemValueOptionInterface[]=[];
  fomrsSectionsItemsValues:FormSectionItemValueInterface[]=[];

  formOptionItemValue=new FormGroup({
    name: new FormControl('',Validators.required),
    label:new FormControl(''),
    idTypeItem:new FormControl(1),
    status:new FormControl(true),
  });

  formItemValueOption=new FormGroup({
    option: new FormControl('',Validators.required),
    value:new FormControl(''),
  });

  constructor(
    private itemValueOptionService:ItemValueOptionsService,
    public dialogRef: MatDialogRef<FormSectionItemDialog>,
    private formSectionItemsService:FormSectionItemsService,
    private catalogsService:CatalogsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  {
    this.idSecction=data["idSecction"];
    this.dataValueItem={
      id:data.valueItem,
      idItem:0,
      option:"",
      value:"",
      status:true
    }; 
  }

  ngOnInit(){
    this.retrieveTypeItem();
    this.retrieveItemOptionValues();
  }

  retrieveTypeItem():void{
    this.catalogsService.getFormularioSectionItem()
    .subscribe(data => {
      this.optiontypeItems=data;
      },
      error=>{
        console.log(error);
      });
  }

  retrieveItemOptionValues(){
    this.itemValueOptionService.getAll(this.dataValueItem.id)
    .subscribe(
      data=>{
        this.itemsValuesOptions=data;
      },
      error=>{
        console.log(error);
      }
    );
  }

  // OnSaveItemWithParent(){
  //   let model=this.formOptionItemValue.value;
  //   model.parentItemValue=this.dataValueItem.id;
  //   model.idSection=this.idSecction;
  //   model.values=this.fomrsSectionsItemsValues;

  //   this.formSectionItemsService.create(model)
  //   .subscribe(
  //     data=>{
  //       console.log(data);
  //     },
  //     error=>{
  //       console.log(error);
  //     }
  //   );
  // }

   OnAddValue(){
    let model=this.formItemValueOption.value;
    model['status']=true;
    this.fomrsSectionsItemsValues.push(model);
  }

  OnRemoveValue(row){
    for (var i = this.fomrsSectionsItemsValues.length - 1; i >= 0; --i) 
    {
      if (this.fomrsSectionsItemsValues[i].option == row.option) {
        this.fomrsSectionsItemsValues.splice(i,1);
      }
    }
  }


  OnChange(event:Event){
    const valor=(event.target as HTMLInputElement).value;
    //this.fomrsSectionsItemsValues=[];
    this.ShowValues(valor);
  }

  ShowValues(valor){
    switch(valor){
      case "2":{
        document.getElementById(`divTblValues_${this.dataValueItem.id}`).className = "visualizar";
        break;
      }
      case "3":{
        document.getElementById(`divTblValues_${this.dataValueItem.id}`).className = "visualizar";
        break;
      }
      case "4":{
        document.getElementById(`divTblValues_${this.dataValueItem.id}`).className = "visualizar";
        break;
      }
      default:{
        document.getElementById(`divTblValues_${this.dataValueItem.id}`).className = "ocultar";
      }
    } 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}