import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AutenticationService } from '../services/Login/autentication.service';
import { UserService } from '../services/user/userService';
import { UtilsService } from '../services/utils/utils.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  UsuarioActual:any;

  constructor(
    private router:Router,
    private authenticationService:AutenticationService,
    private utilsServie:UtilsService,
    private userService:UserService,
    private dialog:MatDialog){
      this.UsuarioActual=JSON.parse(sessionStorage.getItem('X-SYMASUSER'));
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
    document.getElementById("btnSideNav").style.visibility = "hidden";
  }

  salir(){
    this.authenticationService.logout();
  }

  openDialog(){
    const dialogRef = this.dialog.open(UserChangePasswordDialog,{
      width: '500px',
      data:{
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!==undefined){
        console.log(this.UsuarioActual);
        let model={
          ...result.value,
          idUser:this.authenticationService.idUser
        }

        console.log(model);
        this.userService.changePassword(model).subscribe(
          data=>{
            if(data["idUser"]!="00000000-0000-0000-0000-000000000000")
            {
              this.utilsServie.showMessage("Contrasena Actualizada","Correcto");
              this.salir();
            }
            else{
              this.utilsServie.showMessage("Usuario no encontrado","Error")  
            }
          },
          error=>{
            this.utilsServie.showMessage("Error al cambiar password","Error")
          });
      }
    });
  }
}

@Component({
  selector: 'userCahngePasswordDialog',
  templateUrl: 'userCahngePasswordDialog.html',
  //styleUrls:["userCahngePasswordDialog.css"]
})
export class UserChangePasswordDialog implements OnInit {
  UsuarioActual:any;

  changePasswordForm=new FormGroup({
    user:new FormControl(""),
    lastPassword:new FormControl(""),
    newPassword:new FormControl("")
  });

  constructor(
    public authenticationService:AutenticationService,
    public dialogRef: MatDialogRef<UserChangePasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.changePasswordForm.controls["user"].setValue(this.authenticationService.email);
    }

  ngOnInit(){
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}